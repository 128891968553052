import { readState } from "@/__main__/app-state.mjs";
import getData from "@/__main__/get-data.mjs";
import { appURLs } from "@/app/constants.mjs";
import { FeatureFlags } from "@/app/graphql-templates.mjs";
import { CmsFeatureFlagsModel } from "@/data-models/feature-flags.mjs";
import { queryGQL } from "@/util/gql-formatters.mjs";

export default async function fetchData() {
  const featureFlags = await getData(
    queryGQL(appURLs.CMS, FeatureFlags),
    CmsFeatureFlagsModel,
    ["cms", "featureFlags"],
    {
      // We always want to check for new feature flags every 8 hours
      networkBackOffTime: 8 * 60 * 60 * 1000, // 8 hours
    },
  ).catch(() => readState.cms.featureFlags);

  return { featureFlags };
}
